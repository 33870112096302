export const organizationVO = function () {
  return {
    id: '',
    pId: '',
    name: '',
    fullName: '',
    attribute: '',
    no: '',
    phone: '',
    location: '',
    longitude: '',
    latitude: '',
    unitId: '',
    parentId: null,
    parentIds: null,
    sorter: 0,
    iconCode: '',
    show: true,
    memo: '',
    creater: '',
    dateCreated: '',
    lastUpdated: ''
  }
}
// 单位属性
export const OrganizationAttribute = function () {
  return [
    { value: 'unit', name: '单位' },
    { value: 'department', name: '部门' }
  ]
}


