<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="机构名称">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [
                    { type: 'string', required: true, message: '字典名称不能为空' },
                    { type: 'string', max: 30, message: '字典名称不能超过30个字符' },
                  ],
                  trigger: 'change',
                },
              ]"
              placeholder="输入机构名称"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="上级组织（上级单位或者上级部门）">
            <a-select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="请选择上级组织"
              v-decorator="['parentId']"
              style="width: 100%;"
            >
              <a-select-option :key="item.key" :value="item.key" :title="item.value" v-for="item in options">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="机构属性：">
            <a-select
              showSearch
              @change="handleChangeAttribute"
              optionFilterProp="children"
              placeholder="请选择机构属性"
              v-decorator="[
                'attribute',
                {
                  rules: [{ type: 'string', required: true, message: '机构属性为必填项' }],
                  initialValue: 'department',
                  trigger: 'change',
                },
              ]"
              style="width: 100%;"
            >
              <a-select-option
                :key="item.value"
                :value="item.value"
                :title="item.name"
                v-for="item in OrganizationAttribute"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24" v-if="ifUnit">
          <a-form-item label="所属单位">
            <!-- v-show="ifUnit" -->
            <a-select
              showSearch
              allowClear
              optionFilterProp="children"
              placeholder="请选择所属单位"
              v-decorator="[
                'unitId',
                {
                  rules: [{ required: true, message: '机构属性为必填项' }],
                  validateTrigger: 'change',
                  initialValue: undefined,
                },
              ]"
              style="width: 100%;"
            >
              <a-select-option :key="item.key" :value="item.key" :title="item.value" v-for="item in onlyUnit">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="组织编号：">
            <a-input v-decorator="['no']" placeholder="输入组织编号"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="6" :sm="24">
          <a-form-item label="联系方式：">
            <a-input v-decorator="['phone']" placeholder="输入联系方式"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="6" :sm="24">
          <a-form-item label="排序号（显示顺序，asc排列）">
            <a-input-number
              :step="0.1"
              v-decorator="[
                'sorter',
                {
                  rules: [{ type: 'number', required: true, message: '排序号必须为数字' }],
                  trigger: 'change',
                  initialValue: 0,
                },
              ]"
              placeholder="输入排序号"
            ></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经度">
            <a-input v-decorator="['longitude']" placeholder="输入经度"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="纬度">
            <a-input v-decorator="['latitude']" placeholder="输入纬度"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="12" :sm="24">
          <a-form-item label="位置信息">
            <a-textarea v-decorator="['location']" rows="4" placeholder="请输入位置信息" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所有父节点ID信息" v-if="type === 'edit'">
            {{ formItem.parentIds }}({{ formItem.id }})
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="最近更新时间" v-if="type === 'edit'">
            {{ formItem.lastUpdated }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建时间" v-if="type === 'edit'">
            {{ formItem.dateCreated }}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建人（用户Username）" v-if="type === 'edit'">
            {{ formItem.creater }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item v-if="type === 'edit'">
        <a-affix :offsetBottom="10">
          <a-button type="primary" style="float: right;" :loading="ifSub" @click="submitEdit">保存修改 </a-button>
        </a-affix>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'
import { organizationVO, OrganizationAttribute } from '../../../api/vo/settings/organizationVO'

export default {
  name: 'OrganizationForm',
  props: {
    type: {
      type: String,
      default: 'edit',
    },
    defaultParentId: {
      type: Number,
      default: undefined,
    },
    defaultUnitId: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    onlyUnit() {
      const temp = []
      if (this.options) {
        this.options.forEach((x) => {
          if (x.attribute === 'unit') {
            temp.push(x)
          }
        })
      }
      return temp
    },
  },
  watch: {
    defaultParentId(val) {
      if (this.type === 'add') {
        if (val) {
          this.form.setFieldsValue({ parentId: val })
        } else {
          this.form.setFieldsValue({ parentId: undefined })
        }
      }
    },
    defaultUnitId(val) {
      if (this.type === 'add') {
        if (val) {
          console.log(val)
          this.form.setFieldsValue({ unitId: val })
        } else {
          this.form.setFieldsValue({ unitId: undefined })
        }
      }
    },
  },
  data() {
    return {
      ifSchool: false,
      ifUnit: true,
      options: [],
      formItem: organizationVO(),
      OrganizationAttribute: OrganizationAttribute(),
      spinShow: false,
      form: this.$form.createForm(this),
      ifSub: false,
    }
  },
  methods: {
    handleChangeAttribute(value) {
      if (value !== 'unit') {
        this.ifUnit = true
      } else {
        this.ifUnit = false
      }
    },
    /*
     * 通过ID获取数据
     */
    loadData(id) {
      this.formItem = organizationVO()
      this.form.resetFields()
      const self = this
      this.$http(self, {
        url: SERVICE_URLS.organization.view,
        params: {
          id: id,
        },
        loading: 'spinShow',
        noTips: true,
        success: (data) => {
          this.handleChangeAttribute(data.body.content.attribute)
          this.options = data.body.select
          //一定要注意 不然会导致双向绑定选择框无法选中值
          this.$nextTick(() => {
            this.setFields(data.body.content)
          })
        },
      })
    },
    setFields(values) {
      // console.log(values)
      Object.assign(this.formItem, values)
      this.formItem.parentId = values.parentId
      this.form.setFieldsValue({
        name: this.formItem.name,
        no: this.formItem.no,
        phone: this.formItem.phone,
        attribute: this.formItem.attribute,
        location: this.formItem.location,
        longitude: this.formItem.longitude,
        latitude: this.formItem.latitude,
        unitId: this.formItem.unitId,
        parentId: this.formItem.parentId,
        // iconCode: this.formItem.iconCode,

        sorter: this.formItem.sorter,
      })
    },
    setVOFields(values) {
      this.formItem.name = values.name
      this.formItem.no = values.no
      this.formItem.phone = values.phone
      this.formItem.attribute = values.attribute
      this.formItem.location = values.location
      this.formItem.longitude = values.longitude
      this.formItem.latitude = values.latitude
      this.formItem.unitId = values.unitId
      this.formItem.parentId = values.parentId
      // this.formItem.iconCode = values.iconCode
      this.formItem.sorter = values.sorter
    },
    /**
     * 加载下拉框数据，添加时使用
     * @param parentId 默认上级部门选中
     * @param unitId 默认单位选中
     */
    loadSelect(parentId, unitId) {
      console.log(unitId)
      this.$http(this, {
        url: SERVICE_URLS.organization.select,
        loading: 'spinShow',
        noTips: true,
        success: (data) => {
          this.options = data.body
          this.$nextTick(() => {
            this.form.setFieldsValue({
              parentId: parentId,
              unitId: unitId,
            })
          })
        },
      })
    },
    submitEdit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.organization.update,
            data: this.formItem,
            params: {
              id: this.formItem.id,
            },
            success: (data) => {
              this.setFields(data.body)
              this.$emit('editSuccess', data.body)
            },
          })
        } else {
          console.info(err)
        }
      })
    },
    submitAdd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setVOFields(values)
          this.$http(this, {
            url: SERVICE_URLS.organization.create,
            data: this.formItem,
            success: (data) => {
              //添加成功，清空表单数据，并触发回调
              this.$emit('addSuccess', data.body)
              //清空表单数据
              this.formItem = organizationVO()
              this.form.resetFields()
            },
            error: () => {
              this.$emit('addError')
            },
          })
        }
      })
    },
  },
}
</script>
